
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Multiselect from '@vueform/multiselect';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import {
    BannerAssetsObject,
    BannerObject,
  } from '@/store/modules/Assets/BannerModule';

  export default defineComponent({
    name: 'banner-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const { can } = useAbility();
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bannerUpdating'), [
          translate('assets'),
        ]);
      });
      const { data } = await store.dispatch(
        Actions.GET_BANNER,
        route.params.id
      );
      await store.dispatch(Actions.GET_BANNER_ASSETS);
      await store.dispatch(Actions.GET_MERCHANT);

      const goBack = () => {
        router.back();
      };
      const banner = ref<BannerObject>(data.data);
      const thumbnail = ref(banner.value.thumbnail);
      const previewImage = (e) => {
        const file = e.target.files[0];
        thumbnail.value = URL.createObjectURL(file);
      };

      const bannerSchema = Yup.object().shape({
        type: Yup.string().required(() => {
          translate('BANNER_TYPE_IS_REQUIRED_FIELD');
        }),
        screen: Yup.string().when('type', {
          is: 'internal',
          then: Yup.string().required(() =>
            translate('SCREEN_BANNER_IS_REQUIRED_FIELD')
          ),
        }),
        // slug: Yup.string().when('type', {
        //   is: 'internal',
        //   then: Yup.string().required(() =>
        //     translate('ACTION_BANNER_IS_REQUIRED_FIELD')
        //   ),
        // }),
        externalLinkEn: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_EN_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkAr: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_AR_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkWebEn: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_WEB_EN_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkWebAr: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_WEB_AR_IS_REQUIRED_FIELD')
          ),
        }),
        // propertyCategoryId: Yup.number().when('screen', {
        //   is: 'ad',
        //   then: Yup.number().required(() =>
        //     translate('PROPERTY_CATEGORY_IS_REQUIRED_FIELD').label(
        //       'propertyCategoryId'
        //     )
        //   ),
        // }),
        entityId: Yup.string().when('slug', {
          is: 'ad_details',
          then: Yup.string().required(() =>
            translate('ENTITY_ID_IS_REQUIRED_FIELD')
          ),
        }),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        let logoFormData;
        if (values.logo) {
          logoFormData = new FormData();
          logoFormData.append('images', values.logo);
          delete values.logo;
        }

        if (values.slug === 'merchant') {
          values.entityId = Number(merchantId.value);
        } else {
          values.entityId && (values.entityId = Number(values.entityId));
        }

        delete values.logo;
        await store.dispatch(Actions.UPDATE_BANNER, {
          id: route.params.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          if (logoFormData) {
            await store.dispatch(Actions.UPLOAD_BANNER_IMAGE, {
              data: logoFormData,
              bannerId: banner.value.id,
            });
          }

          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_BANNER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'banners-listing' });
          });
        }
      };
      const bannerAssets = ref<BannerAssetsObject>(
        store.getters.bannerAssetsList
      );
      const isRelatedId = () => {
        bannerAssets.value.typeSlug.find((slug) => {
          return slug.id === banner.value.slug && slug.isRelatedID;
        });
      };
      const merchants = computed(() => store.getters.merchantList);

      const merchantId = ref(
        merchants.value.length > 0 ? merchants.value[0].id : 0
      );
      const selectedMerchant = () => {
        if (!merchants.value) return;
        const merchant = merchants.value.findIndex(
          ({ id: merchant }) => merchant === banner.value.entityId
        );
        if (banner.value.slug === 'merchant') {
          if (merchants.value)
            merchantId.value =
              merchants.value[merchant].id || merchants.value[0].id;
        }

        return merchantId;
      };
      selectedMerchant();
      await store.dispatch(Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID, {
        adType: 1,
      });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        propertyTypesCategories: store.getters.propertyAllCategoryList,
        bannerAssets,
        merchants,
        onSubmitUpdate,
        submitButton,
        translate,
        bannerSchema,
        goBack,
        can,
        banner,
        loading,
        isRelatedId,
        selectedMerchant,
        previewImage,
        thumbnail,
        merchantId,
      };
    },
  });
